import React, { useEffect, useMemo, useState } from 'react'
import { CustomSelect, InlineErrorMessage, Option } from '../../Shared'
import { ThemeVersion } from '../../../types'
import { TeamMemberForm, TeamMemberSoucreEnum } from '../../MyAccount'
import { useDisclosure } from '@chakra-ui/core'
import { CreatePortalTeamMember_createPortalTeamMember_TeamMember } from '../../../schema/mutations/__generated__/CreatePortalTeamMember'
import { OrderFormData } from '../OrderCreate/OrderFormDataTypes'
import { useQuery } from 'react-apollo'
import { isROWCustomer } from '../../lib'
import { Icons } from '@chakra-ui/core/dist/theme/icons'
import { useUser } from '../../../context/user-context'
import { useTranslation } from 'react-i18next'
import { GetTreatingPhysicians, GetTreatingPhysicians_treatingPhysicians } from '../../../schema/queries/__generated__/GetTreatingPhysicians'
import { GET_TREATING_PHYSICIANS } from '../../../schema/queries/TeamMembersQueries'

interface Props {
  formData: OrderFormData
  setFormData: (value: React.SetStateAction<OrderFormData>) => void
}

export const TreatingPhysician: React.FC<Props> = ({
  formData,
  setFormData,
}) => {
  const { t } = useTranslation(['orderCreate', 'orderShowScreen'])
  const user = useUser()
  const [treatingPhysicians, setTreatingPhysicians] = useState<GetTreatingPhysicians_treatingPhysicians[]>([])
  const { data: treatingPhysiciansData } = useQuery<GetTreatingPhysicians>(GET_TREATING_PHYSICIANS, {
    fetchPolicy: 'cache-first',
  })
  const {
    isOpen: isTPOpen,
    onOpen: onTPOpen,
    onClose: onTPClose,
  } = useDisclosure()

  useEffect(() => {
    if (treatingPhysiciansData) {
      setTreatingPhysicians(treatingPhysiciansData.treatingPhysicians ?? [])
    }
  }, [treatingPhysiciansData])

  const onTreatingPhysicianChange = (option: Option | null): void => {
    if (option?.value === 'add_member') {
      // Open add team member modal
      onTPOpen()
    } else {
      setFormData((prevFormData: OrderFormData) => {
        return {
          ...prevFormData,
          treatingPhysicianId: option?.value ?? undefined,
          treatingPhysicianName: option?.label ?? undefined,
        }
      })
    }
  }

  const handleTPModalClose = (
    createdUser?: CreatePortalTeamMember_createPortalTeamMember_TeamMember
  ): void => {
    onTPClose()
    if (createdUser) {
      setFormData((prevFormData: OrderFormData) => {
        return {
          ...prevFormData,
          treatingPhysicianId: createdUser.gid,
          treatingPhysicianName: createdUser.name,
        }
      })
    }
  }

  const treatingPhysicianOptions = useMemo(() => {
    const options: Option[] = []
    if (treatingPhysicians) {
      treatingPhysicians.map(t => {
        return options.push({
          value: t.gid,
          label: t.name,
        })
      })
    }
    options.push({
      value: 'add_member',
      label: t('orderCreateWidget.treatingPhysicianField.addMenuLink', {
        ns: ['orderCreate'],
      }),
      isLink: true,
    })
    return options
  }, [treatingPhysicians])

  const treatingPhysicianOptionVal = useMemo(() => {
    const value: Option | undefined = treatingPhysicianOptions.find(
      o => o.value === formData.treatingPhysicianId
    )
    return value ?? null
  }, [
    treatingPhysicians,
    formData.treatingPhysicianId,
    formData.treatingPhysicianName,
  ])

  return (
    <>
      <CustomSelect
        label={t('orderCreateWidget.treatingPhysicianField.label', {
          ns: ['orderCreate'],
        })}
        placeholder={t('orderCreateWidget.treatingPhysicianField.placeholder', {
          ns: ['orderCreate'],
        })}
        value={treatingPhysicianOptionVal}
        onChange={option => onTreatingPhysicianChange(option as Option)}
        options={treatingPhysicianOptions}
        isSearchable={false}
        clearable={true}
        version={ThemeVersion.V2}
        classNamePrefix="treating-physician-select"
      />
      <TeamMemberForm
        isOpen={isTPOpen}
        onClose={handleTPModalClose}
        headingTitle={t(
          'orderCreateWidget.treatingPhysicianField.formHeading',
          {
            ns: ['orderCreate'],
          }
        )}
        submitBtnLabel={t(
          'orderCreateWidget.treatingPhysicianField.formSubmitBtn',
          {
            ns: ['orderCreate'],
          }
        )}
        source={TeamMemberSoucreEnum.ADD_TREATING_PHYSICIAN}
      />
      {isROWCustomer(user) &&
        treatingPhysicians.find(
          o => o.gid === formData.treatingPhysicianId
        ) && (
          <InlineErrorMessage version={ThemeVersion.V2} icon={'info' as Icons}>
            {t('treatingPhysForm.message', { ns: ['orderShowScreen'] })}
          </InlineErrorMessage>
        )}
    </>
  )
}
