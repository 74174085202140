import gql from 'graphql-tag'
import { FULL_TEAM_MEMBER_FRAGMENT } from '../fragments/TeamMemberFragment'
import { FULL_TREATING_PHYSICIAN_FRAGMENT } from '../fragments/FullTreatingPhysicianFragment'

export const GET_TEAM_MEMBERS = gql`
  query GetTeamMembers {
    teamMembers {
      ...FullTeamMemberFragment
    }
  }
  ${FULL_TEAM_MEMBER_FRAGMENT}
`

export const GET_TREATING_PHYSICIANS = gql`
  query GetTreatingPhysicians {
    treatingPhysicians {
      ...FullTreatingPhysicianFragment
    }
  }
  ${FULL_TREATING_PHYSICIAN_FRAGMENT}
`
