import gql from 'graphql-tag'

export const FULL_TEAM_MEMBER_FRAGMENT = gql`
  fragment FullTeamMemberFragment on TeamMember {
    id
    gid
    name
    firstName
    lastName
    email
    profession
    otherProfession
    npi
    isPortalUser
    portalUserId
    removalRequestedAt
  }
`
