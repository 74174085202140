import {
  Box,
  Divider,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalHeader,
  Text,
} from '@chakra-ui/core'
import { Icons } from '@chakra-ui/core/dist/theme/icons'
import React, { ChangeEvent, useEffect, useRef, useState } from 'react'
import { useLazyQuery, useMutation } from 'react-apollo'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import * as yup from 'yup'
import {
  TeamMemberProfessionEnum,
  UpdateTeamMemberInput,
} from '../../../__generated__/globalTypes'
import { useUser } from '../../../context/user-context'
import { useDisplayReferringPhysician } from '../../../hooks'
import i18n from '../../../i18n'
import {
  CreatePortalTeamMember,
  CreatePortalTeamMemberVariables,
  CreatePortalTeamMember_createPortalTeamMember_TeamMember,
} from '../../../schema/mutations/__generated__/CreatePortalTeamMember'
import {
  UpdatePortalTeamMember,
  UpdatePortalTeamMemberVariables,
} from '../../../schema/mutations/__generated__/UpdatePortalTeamMember'
import {
  CREATE_PORTAL_TEAM_MEMBER_MUTATION,
  UPDATE_PORTAL_TEAM_MEMBER_MUTATION,
} from '../../../schema/mutations/TeamMemberMutations'
import { GetTeamMembers_teamMembers } from '../../../schema/queries/__generated__/GetTeamMembers'
import {
  NPISearch,
  NPISearchVariables,
  NPISearch_npiSearch_results,
} from '../../../schema/queries/__generated__/NPISearch'
import { NPI_SEARCH_QUERY } from '../../../schema/queries/NPISearchQueries'
import { themeUtils } from '../../../themeUtils'
import { ThemeVersion } from '../../../types'
import { Notification } from '../../../utils'
import { isROWCustomer, isUSCustomer } from '../../lib'
import {
  Button,
  Heading,
  InlineErrorMessage,
  Input,
  ModalContent,
  ModalOverlay,
  SingleSelect,
} from '../../Shared'
import { getProfessionOptions } from '../BasicDetails'
import { NPIFieldWidget } from '../NPI/NPIFieldWidget'

export enum TeamMemberSoucreEnum {
  ADD_TREATING_PHYSICIAN = 'ADD_TREATING_PHYSICIAN',
  ADD_REFERRING_PHYSICIAN = 'ADD_REFERRING_PHYSICIAN',
}
interface CommonProps {
  onClose: (
    createdUser?: CreatePortalTeamMember_createPortalTeamMember_TeamMember
  ) => void
  member?: GetTeamMembers_teamMembers
  submitBtnLabel?: string
  source?: string
}

interface Props extends CommonProps {
  isOpen: boolean
  headingTitle?: string
}

interface BodyProps extends CommonProps {
  cancelBtn?: JSX.Element
}

interface FormData {
  firstName: string
  lastName: string
  profession: TeamMemberProfessionEnum
  otherProfession: string
  email: string
  npi: string
}

const npiPattern = /^\d{10}$/

const validationSchema = () =>
  yup.object().shape({
    firstName: yup
      .string()
      .required(
        i18n.t('add.firstName.requiredError', { ns: ['teamMemberSection'] })
      ),
    lastName: yup
      .string()
      .required(
        i18n.t('add.lastName.requiredError', { ns: ['teamMemberSection'] })
      ),
    email: yup
      .string()
      .email(
        i18n.t('add.email.validationError', { ns: ['teamMemberSection'] })
      ),
    profession: yup
      .string()
      .required(
        i18n.t('add.profession.requiredError', { ns: ['teamMemberSection'] })
      ),
    otherProfession: yup.string(),
    npi: yup.string().matches(npiPattern, {
      message: i18n.t('npiNumberField.validationError', {
        ns: ['npiWidget'],
      }),
      excludeEmptyString: true,
    }),
  })

const { green, blue } = themeUtils.colors

export const TeamMemberForm: React.FC<Props> = ({
  isOpen,
  onClose,
  member,
  headingTitle = i18n.t('add.defaultHeadingTitle', {
    ns: ['teamMemberSection'],
  }),
  submitBtnLabel,
  source,
}) => {
  return (
    <>
      <Modal
        isOpen={isOpen}
        onClose={() => onClose()}
        isCentered
        closeOnOverlayClick={false}
        closeOnEsc={false}
      >
        <ModalOverlay />
        <ModalContent maxWidth="48.5rem" justifyContent="center">
          <ModalHeader padding="0">
            <Heading color={blue} as="h2" marginBottom="0">
              {headingTitle}
            </Heading>
          </ModalHeader>
          <ModalCloseButton _focus={undefined} data-cy="modal-close-btn" />

          <TeamMemberFormBody
            member={member}
            onClose={onClose}
            submitBtnLabel={submitBtnLabel}
            source={source}
          />
        </ModalContent>
      </Modal>
    </>
  )
}

export const TeamMemberFormBody: React.FC<BodyProps> = ({
  onClose,
  member,
  submitBtnLabel = i18n.t('add.defaultSubmitBtnLabel', {
    ns: ['teamMemberSection'],
  }),
  source,
  cancelBtn,
}) => {
  const { t } = useTranslation(['teamMemberSection', 'npiWidget'])
  const user = useUser()
  const [isNPISearchSection, setNPISearchSection] = useState<boolean>(false)

  const displayReferringPhysician = useDisplayReferringPhysician()

  const [
    createTeamMember,
    { loading: loadingCreate, error: errorCreate },
  ] = useMutation<CreatePortalTeamMember, CreatePortalTeamMemberVariables>(
    CREATE_PORTAL_TEAM_MEMBER_MUTATION
  )

  const [
    updateTeamMember,
    { loading: loadingUpdate, error: errorUpdate },
  ] = useMutation<UpdatePortalTeamMember, UpdatePortalTeamMemberVariables>(
    UPDATE_PORTAL_TEAM_MEMBER_MUTATION
  )

  useEffect(() => {
    if (errorCreate) Notification.error(errorCreate.message)
    if (errorUpdate) Notification.error(errorUpdate.message)
  }, [errorCreate, errorUpdate])

  const [
    searchNPI,
    { data: searchNPIDATA, loading: searchNPILoading },
  ] = useLazyQuery<NPISearch, NPISearchVariables>(NPI_SEARCH_QUERY)

  const {
    register,
    handleSubmit,
    errors,
    watch,
    setValue,
    clearError,
    setError,
    reset,
    triggerValidation,
  } = useForm<FormData>({
    validationSchema: validationSchema(),
    mode: 'onChange',
    defaultValues: {
      firstName: (member && member.firstName) || '',
      lastName: (member && member.lastName) || '',
      email: (member && member.email) || '',
      profession:
        (member && member.profession) ||
        (source === TeamMemberSoucreEnum.ADD_TREATING_PHYSICIAN
          ? TeamMemberProfessionEnum.treating_physician
          : source === TeamMemberSoucreEnum.ADD_REFERRING_PHYSICIAN
          ? TeamMemberProfessionEnum.referring_physician
          : undefined),
      otherProfession:
        (member && member.profession === TeamMemberProfessionEnum.other
          ? member.otherProfession
          : '') || '',
      npi:
        (member &&
        [
          TeamMemberProfessionEnum.treating_physician,
          TeamMemberProfessionEnum.referring_physician,
        ].includes(member.profession)
          ? member.npi
          : '') || '',
    },
  })

  useEffect(() => {
    register({ name: 'profession' })
    register({ name: 'npi' })
  }, [register])

  const profession = watch('profession') as TeamMemberProfessionEnum
  const firstName = watch('firstName') as string
  const lastName = watch('lastName') as string
  const npi = watch('npi') as string

  const debounceTimeout = useRef<NodeJS.Timeout>()

  useEffect(() => {
    callingNPISearchWithNPI()
  }, [npi])

  useEffect(() => {
    if (isNPISearchSection) {
      callingNPISearchWithNames()
    }
  }, [firstName, lastName])

  const callingNPISearchWithNames = (useDebounce = true): void => {
    if (
      ![
        TeamMemberProfessionEnum.treating_physician,
        TeamMemberProfessionEnum.referring_physician,
      ].includes(profession)
    ) {
      return
    }

    if (useDebounce) {
      // Clear any existing debounce timeout
      if (debounceTimeout.current) {
        clearTimeout(debounceTimeout.current)
      }
      // Set a new debounce timeout
      debounceTimeout.current = setTimeout(() => {
        // Make your API request here
        searchNPI({
          variables: {
            firstName: firstName,
            lastName: lastName,
          },
        })
      }, 500) // Adjust the delay (in milliseconds) as needed
    } else {
      searchNPI({
        variables: {
          firstName: firstName,
          lastName: lastName,
        },
      })
    }
  }

  const callingNPISearchWithNPI = (useDebounce = true): void => {
    if (
      ![
        TeamMemberProfessionEnum.treating_physician,
        TeamMemberProfessionEnum.referring_physician,
      ].includes(profession)
    ) {
      return
    }
    const isNpiValid = npiPattern.test(npi)
    if (npi && isNpiValid) {
      if (useDebounce) {
        // Clear any existing debounce timeout
        if (debounceTimeout.current) {
          clearTimeout(debounceTimeout.current)
        }
        // Set a new debounce timeout
        debounceTimeout.current = setTimeout(() => {
          // Make your API request here
          searchNPI({
            variables: {
              number: npi,
              taxonomyDescription: '',
            },
          })
        }, 500) // Adjust the delay (in milliseconds) as needed
      } else {
        searchNPI({
          variables: {
            number: npi,
            taxonomyDescription: '',
          },
        })
      }
    } else if (npi && !isNpiValid) {
      triggerValidation('npi')
    }
  }

  const onChangeNPI = (event: ChangeEvent<HTMLInputElement>): void => {
    setValue('npi', event.target.value)
    clearError('npi')
  }

  const onProfessionChange = (event: ChangeEvent<HTMLInputElement>): void => {
    const value = event.target.value as TeamMemberProfessionEnum
    setValue('profession', value)
    clearError('profession')

    // clear value of npi if value is not Treating Physician
    if (
      ![
        TeamMemberProfessionEnum.treating_physician,
        TeamMemberProfessionEnum.referring_physician,
      ].includes(value)
    ) {
      setValue('npi', '')
      clearError('npi')
    }

    // clear value of other Profession if value is not others
    if (value !== TeamMemberProfessionEnum.other) {
      setValue('otherProfession', '')
      clearError('otherProfession')
    }
  }
  // submitting the form
  const onSubmit = async (results: FormData): Promise<void> => {
    if (member) {
      let input: UpdateTeamMemberInput = {
        id: member.id,
        profession: results.profession,
        otherProfession: results.otherProfession
          ? results.otherProfession
          : null,
        npi: results.npi ? results.npi : null,
      }

      if (!member.isPortalUser) {
        input = {
          ...input,
          firstName: results.firstName,
          lastName: results.lastName,
          email: results.email,
        }
      }

      const { data, errors } = await updateTeamMember({
        variables: {
          input: input,
        },
      })
      if (errors) {
        errors.forEach(error => Notification.error(error.message))
      } else {
        if (data && data.updatePortalTeamMember) {
          if (data.updatePortalTeamMember.__typename === 'Error') {
            data.updatePortalTeamMember.errors.forEach(error => {
              if (error.path) {
                setError(error.path[0], error.type, error.message)
              }
            })
            Notification.error(
              t('add.toast.failure', {
                ns: ['teamMemberSection'],
              }),
              {
                timeout: 3000,
              }
            )
          } else {
            Notification.success(
              t('add.toast.success', {
                ns: ['teamMemberSection'],
              })
            )
            onModalClose()
          }
        }
      }
    } else {
      const { data, errors } = await createTeamMember({
        variables: {
          input: {
            firstName: results.firstName,
            lastName: results.lastName,
            email: results.email,
            profession: results.profession,
            otherProfession: results.otherProfession ?? null,
            npi: results.npi ?? null,
            customerId: user ? user.customer.id : '',
          },
        },
        refetchQueries:
          source === TeamMemberSoucreEnum.ADD_TREATING_PHYSICIAN ||
          source === TeamMemberSoucreEnum.ADD_REFERRING_PHYSICIAN
            ? ['GetCustomer', 'GetTreatingPhysicians']
            : ['GetTeamMembers', 'GetTreatingPhysicians'],
      })

      if (errors) {
        errors.forEach(error => Notification.error(error.message))
      } else {
        if (data) {
          if (data.createPortalTeamMember.__typename === 'Error') {
            data.createPortalTeamMember.errors.forEach(error => {
              if (error.path) {
                setError(error.path[0], error.type, error.message)
              }
            })
            Notification.error(
              source === TeamMemberSoucreEnum.ADD_TREATING_PHYSICIAN
                ? t('add.toast.createTPFailed', {
                    ns: ['teamMemberSection'],
                  })
                : source === TeamMemberSoucreEnum.ADD_REFERRING_PHYSICIAN
                ? t('add.toast.createRPFailed', {
                    ns: ['teamMemberSection'],
                  })
                : t('add.toast.createTMFailed', {
                    ns: ['teamMemberSection'],
                  }),
              {
                timeout: 3000,
              }
            )
          } else {
            Notification.success(
              source === TeamMemberSoucreEnum.ADD_TREATING_PHYSICIAN
                ? t('add.toast.createTPSuccess', {
                    ns: ['teamMemberSection'],
                  })
                : source === TeamMemberSoucreEnum.ADD_REFERRING_PHYSICIAN
                ? t('add.toast.createTRSuccess', {
                    ns: ['teamMemberSection'],
                  })
                : t('add.toast.createTMSuccess', {
                    ns: ['teamMemberSection'],
                  })
            )
            onModalClose(data.createPortalTeamMember)
          }
        } else {
          Notification.error(
            t('add.toast.error', {
              ns: ['teamMemberSection'],
            })
          )
        }
      }
    }
  }

  const onModalClose = (
    createdUser?: CreatePortalTeamMember_createPortalTeamMember_TeamMember
  ): void => {
    reset()
    onClose(createdUser)
    setNPISearchSection(false)
  }

  const onSelectNPI = (result: NPISearch_npiSearch_results): void => {
    const npiValue = result.number ?? ''
    setValue('npi', npiValue)
    clearError('npi')
    onToggleNPISection()
  }

  const onToggleNPISection = (): void => {
    if (!isNPISearchSection === true) {
      triggerValidation()
      if (!firstName || !lastName) {
        return
      }
      callingNPISearchWithNames(false)
    } else {
      if (member && member.isPortalUser) {
        setValue('firstName', member.firstName)
        clearError('firstName')
        setValue('lastName', member.lastName)
        clearError('lastName')
      }
    }
    setNPISearchSection(!isNPISearchSection)
  }

  return (
    <ModalBody borderTop="1px solid #EFEFEF" padding="2rem 0 0 0">
      <Box>
        <form
          id="teamMemberForm"
          autoComplete="off"
          acceptCharset="UTF-8"
          noValidate
          onSubmit={handleSubmit(onSubmit)}
        >
          {isNPISearchSection && (
            <Text
              color={themeUtils.colors.blue}
              fontSize={'xl'}
              fontWeight={'bold'}
              margin={'0 0 1rem 0'}
            >
              <span
                style={{
                  cursor: 'pointer',
                  textDecoration: 'underline',
                }}
                onClick={onToggleNPISection}
              >
                {t('closeSearchLabel', {
                  ns: ['npiWidget'],
                })}
              </span>
            </Text>
          )}
          <Input
            label={t('add.firstName.label', {
              ns: ['teamMemberSection'],
            })}
            type="text"
            id="firstName"
            name="firstName"
            innerRef={register}
            invalid={!!errors.firstName}
            error={errors.firstName && errors.firstName.message}
            data-cy="member-firstname-input"
            disabled={
              isNPISearchSection ? false : member && member.isPortalUser
            }
            isRequired
          />
          <Input
            label={t('add.lastName.label', {
              ns: ['teamMemberSection'],
            })}
            type="text"
            id="lastName"
            name="lastName"
            innerRef={register}
            invalid={!!errors.lastName}
            error={errors.lastName && errors.lastName.message}
            data-cy="member-lastName-input"
            disabled={
              isNPISearchSection ? false : member && member.isPortalUser
            }
            isRequired
          />

          {!isROWCustomer(user) && (
            <Input
              label={t('add.email.label', {
                ns: ['teamMemberSection'],
              })}
              type="email"
              id="email"
              name="email"
              innerRef={register}
              invalid={!!errors.email}
              error={errors.email && errors.email.message}
              disabled={member && member.isPortalUser}
              data-cy="member-email-input"
              hidden={isNPISearchSection}
            />
          )}
          <SingleSelect
            label={t('add.profession.label', {
              ns: ['teamMemberSection'],
            })}
            name="Profession"
            value={profession}
            onChange={onProfessionChange}
            options={getProfessionOptions(displayReferringPhysician)}
            error={errors.profession && errors.profession.message}
            data-cy="member-profession-select"
            isRequired
            disabled={
              source === TeamMemberSoucreEnum.ADD_TREATING_PHYSICIAN ||
              source === TeamMemberSoucreEnum.ADD_REFERRING_PHYSICIAN
            }
            hidden={isNPISearchSection}
          />
          {isROWCustomer(user) &&
            (source === TeamMemberSoucreEnum.ADD_TREATING_PHYSICIAN ||
              source === TeamMemberSoucreEnum.ADD_REFERRING_PHYSICIAN) && (
              <InlineErrorMessage
                version={ThemeVersion.V2}
                icon={'info' as Icons}
              >
                {source === TeamMemberSoucreEnum.ADD_TREATING_PHYSICIAN
                  ? t('add.profession.rowInfo', {
                      ns: ['teamMemberSection'],
                    })
                  : source === TeamMemberSoucreEnum.ADD_REFERRING_PHYSICIAN
                  ? t('add.profession.rowInfoReferring', {
                      ns: ['teamMemberSection'],
                    })
                  : null}
              </InlineErrorMessage>
            )}
          {profession === TeamMemberProfessionEnum.other && (
            <Input
              label={t('add.otherProfession.label', {
                ns: ['teamMemberSection'],
              })}
              type="text"
              id="otherProfession"
              name="otherProfession"
              innerRef={register}
              invalid={!!errors.otherProfession}
              error={errors.otherProfession && errors.otherProfession.message}
              data-cy="member-otherProfession-input"
            />
          )}
          {isUSCustomer(user) &&
            [
              TeamMemberProfessionEnum.treating_physician,
              TeamMemberProfessionEnum.referring_physician,
            ].includes(profession) && (
              <>
                {!isNPISearchSection && (
                  <Input
                    label={t('npiNumberField.tmFormLabel', {
                      ns: ['npiWidget'],
                    })}
                    type="text"
                    id="npi"
                    name="npi"
                    onChange={onChangeNPI}
                    // innerRef={register}
                    value={npi}
                    invalid={!!errors.npi}
                    error={errors.npi && errors.npi.message}
                    data-cy="member-npi-input"
                  />
                )}

                <NPIFieldWidget
                  profession={profession}
                  isNPISearchMode={isNPISearchSection}
                  onNPISearchLinkClick={onToggleNPISection}
                  npi={npi}
                  data={!firstName || !lastName ? undefined : searchNPIDATA}
                  loading={searchNPILoading}
                  onSelectNPI={onSelectNPI}
                  linkLabel={t('npiNumberField.tmSearchLinkLabel', {
                    ns: ['npiWidget'],
                  })}
                />
              </>
            )}

          <Divider marginTop={'1.5rem'} marginBottom={'1.5rem'} border={'1'} />
          <div style={{ padding: '0 1rem' }}>
            <Button
              type="submit"
              backgroundColor={green}
              isFullWidth
              isLoading={loadingCreate || loadingUpdate}
              isDisabled={isNPISearchSection || searchNPILoading}
              data-cy="member-submit-btn"
            >
              {submitBtnLabel}
            </Button>
            {cancelBtn}
          </div>
        </form>
      </Box>
    </ModalBody>
  )
}
