import gql from 'graphql-tag'
import { FULL_TEAM_MEMBER_FRAGMENT } from '../fragments/TeamMemberFragment'

export const CREATE_PORTAL_TEAM_MEMBER_MUTATION = gql`
  mutation CreatePortalTeamMember($input: CreateTeamMemberInput!) {
    createPortalTeamMember(input: $input) {
      __typename
      ... on TeamMember {
        id
        gid
        name
      }
      ... on Error {
        errors {
          message
          path
          type
        }
      }
    }
  }
`

export const UPDATE_PORTAL_TEAM_MEMBER_MUTATION = gql`
  mutation UpdatePortalTeamMember($input: UpdateTeamMemberInput!) {
    updatePortalTeamMember(input: $input) {
      __typename
      ... on TeamMember {
        ...FullTeamMemberFragment
      }
      ... on Error {
        errors {
          message
          path
          type
        }
      }
    }
  }
  ${FULL_TEAM_MEMBER_FRAGMENT}
`
